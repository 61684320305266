/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Bio = ({ author = {} }) => {

  if (!author)
    return <div/>

  const { name, bio } = author
  const image = getImage(author.image)

  return ( 
    <div className="bio">
      {image && (
      <GatsbyImage
        className="bio-avatar"
        style={{
            width: 50,
            height: 50,
        }}
        image={image}
        quality={95}
        alt="Author image"
        backgroundColor={true}
      />
      )}
      {name && (
        <p>
          <strong>{name}</strong>
          <br/>
          {bio}
        </p>
      )}
    </div>
  )
}

export default Bio
